// book
import book1 from '../asset/imgs/book1.jpg';
import book2 from '../asset/imgs/book2.jpg';
import book3 from '../asset/imgs/book3.jpg';
import book4 from '../asset/imgs/book4.jpeg';
import book5 from '../asset/imgs/book5.jpeg';
import book6 from '../asset/imgs/book6.jpeg';
import book7 from '../asset/imgs/book7.jpeg';
import book8 from '../asset/imgs/book8.jpg';
import book_TheUmbralStorm from '../asset/imgs/book_TheUmbralStorm.jpeg';
import book_TheBookofZog from '../asset/imgs/book_TheBookofZog.jpg';
import book_ThePaleBlade from '../asset/imgs/book_ThePaleBlade.jpg';

// banner
import full_TheShadowsofDust from '../asset/imgs/full_TheShadowsofDust.jpg';
import full_TheShadowKing from '../asset/imgs/full_TheShadowKing.jpg';
import full_TheSilverSorceress from '../asset/imgs/full_TheSilverSorceress.jpg';
import full_TheRaveling from '../asset/imgs/full_TheRaveling.jpg';
import full_TheCleansingFlame from '../asset/imgs/full_TheCleansingFlame.jpg';

// background image
import back_1 from '../asset/imgs/mat1.png';


// article or comments
import { crimson, silver, shadow, dust, cleansing, twilight, hollow, soiree, sharded1, zog, blade, } from './bookInfo';

// each book
const book_crimson = {
    name: 'The Crimson Queen',
    id: '1',
    imgUrl: book1,
    fullImgUrl: full_TheRaveling,
    linkUrl: 'http://mybook.to/CrimsonQueen',
    type: 'ebook, audiobook, paperback',
    intro: 'Book One of the Raveling',
    info: crimson,
};

const book_silver = {
    name: 'The Silver Sorceress',
    id: '2',
    imgUrl: book2,
    fullImgUrl: full_TheSilverSorceress,
    linkUrl: 'http://mybook.to/Sorceress',
    type: 'ebook, audiobook, paperback',
    intro: 'Book Two of the Raveling',
    info: silver,
};

const book_shadow = {
    name: 'The Shadow King',
    id: '3',
    imgUrl: book3,
    fullImgUrl: full_TheShadowKing,
    linkUrl: 'http://mybook.to/TheShadowKing',
    type: 'ebook, audiobook, paperback',
    intro: 'Book Three of the Raveling',
    info: shadow,
};

const book_dust = {
    name: 'The Shadows of Dust',
    id: '4',
    imgUrl: book4,
    fullImgUrl: full_TheShadowsofDust,
    linkUrl: 'http://mybook.to/TheShadowsofDust',
    type: 'ebook, audiobook, paperback',
    intro: '',
    info: dust,
};

const book_cleansing = {
    name: 'The Cleansing Flame',
    id: '5',
    imgUrl: book5,
    fullImgUrl: full_TheCleansingFlame,
    linkUrl: 'http://mybook.to/TheCleansingFlame',
    type: 'ebook, audiobook',
    intro: 'Book One of Swords and Saints',
    info: cleansing,
};

const book_twilight = {
    name: 'The Twilight Empire',
    id: '6',
    imgUrl: book6,
    fullImgUrl: full_TheCleansingFlame,
    linkUrl: 'http://mybook.to/TheTwilightEmpire',
    type: 'ebook, audiobook',
    intro: 'Book Two of Swords and Saints',
    info: twilight,
};

const book_hollow = {
    name: 'The Hollow God',
    id: '7',
    imgUrl: book7,
    fullImgUrl: full_TheCleansingFlame,
    linkUrl: 'http://mybook.to/TheHollowGod',
    type: 'ebook, audiobook',
    intro: 'Book Three of Swords and Saints',
    info: hollow,
};

const book_soiree = {
    name: `The Manticore's Soiree`,
    id: '8',
    imgUrl: book8,
    fullImgUrl: full_TheCleansingFlame,
    linkUrl: 'http://mybook.to/Manticore',
    type: 'ebook, audiobook, paperback',
    intro: '',
    info: soiree,
};

const book_sharded1 = {
    name: `The Umbral Storm`,
    id: '9',
    imgUrl: book_TheUmbralStorm,
    fullImgUrl: full_TheCleansingFlame,
    linkUrl: 'http://mybook.to/TheUmbralStorm',
    type: 'ebook, paperback',
    intro: 'The Sharded Few Book 1',
    info: sharded1,
};

const book_zog = {
    name: `The Book of Zog`,
    id: '10',
    imgUrl: book_TheBookofZog,
    fullImgUrl: back_1,
    linkUrl: 'http://mybook.to/TheBookofZog',
    type: 'ebook, paperback',
    intro: '',
    info: zog,
};

const book_blade = {
    name: `The Pale Blade`,
    id: '11',
    imgUrl: book_ThePaleBlade,
    fullImgUrl: back_1,
    linkUrl: 'http://mybook.to/ThePaleBlade',
    type: 'ebook, paperback',
    intro: 'Empire of Flame & Fang Book 1',
    info: blade,
};


// book detail - related books
book_crimson.related = [book_silver, book_shadow];
book_silver.related = [book_crimson, book_shadow];
book_shadow.related = [book_crimson, book_silver];
book_dust.related = [];
book_cleansing.related = [book_twilight, book_hollow];
book_twilight.related = [book_cleansing, book_hollow];
book_hollow.related = [book_cleansing, book_twilight];
book_soiree.related = [];

// book list
export const bookList = [book_crimson, book_silver, book_shadow, book_dust, book_sharded1, book_blade, book_zog, book_cleansing, book_twilight, book_hollow, book_soiree];

// new release
export const newsList = [
    {
        name: 'The Pale Blade',
        id: '11',
        imgUrl: book_ThePaleBlade,
        intro: 'Released December 2023'
    },
    {
        name: 'The Book of Zog',
        id: '10',
        imgUrl: book_TheBookofZog,
        intro: 'Released September 2022'
    },
    {
        name: 'The Umbral Storm',
        id: '9',
        imgUrl: book_TheUmbralStorm,
        intro: 'Released May 2022'
    },
    {
        name: 'The Shadows of Dust',
        id: '4',
        imgUrl: book4,
        intro: 'Released January 2021'
    },
    {
        name: 'The Hollow God',
        id: '7',
        imgUrl: book7,
        intro: 'Released July 2020',
    },
    {
        name: 'The Shadow King',
        id: '3',
        imgUrl: book3,
        intro: 'Released November 2019',
    },
];

// big menu
export const menuList = [
    {
        name: 'HOME',
        path: '/home',
        child: [],
    },
    {
        name: 'ABOUT',
        path: '/about',
        child: [],
    },
    {
        name: 'NOVELS',
        path: '/novels',
        child: bookList,
    },
    // {
    //     name: 'BLOG',
    //     path: '/blog',
    //     child: [],
    // },
    {
        name: 'NEW RELEASE',
        path: '/release',
        child: [],
    },
    {
        name: 'CONTACT',
        path: '/contact',
        child: []
    },

];

// banner
export const banners = [full_TheShadowsofDust, full_TheShadowKing, full_TheSilverSorceress, full_TheRaveling, full_TheCleansingFlame];

// society link
export const links = [
    'https://www.facebook.com/groups/120519988414163/',
    'https://www.instagram.com/alec.hutson',
    'https://www.goodreads.com/author/show/16048391.Alec_Hutson',
];






