export const crimson = [
    `2016 Ink and Insights Competition – Judges’ Favorite`,
    `2017 Readers' Favorite Award for Epic Fantasy`,
    `2nd in the 2017 SPFBO`,
    `#1 Amazon Best Seller in Epic Fantasy`,
    `Long ago the world fell into twilight, when the great empires of old consumed each other in sorcerous cataclysms. In the south the Star Towers fell, swallowed by the sea, while the black glaciers descended upon the northern holdfasts, entombing the cities of Min-Ceruth in ice and sorcery. Then from the ancient empire of Menekar the paladins of Ama came, putting every surviving sorcerer to the sword and cleansing their taint from the land for the radiant glory of their lord.`,
    `The pulse of magic slowed, fading like the heartbeat of a dying man.`,
    `But after a thousand years it has begun to quicken again.`,
    `In a small fishing village a boy with strange powers comes of age...`,
    `"If you're looking for the next big thing in epic fantasy . . . then look no further."  - Will Wight, bestselling author of Unsouled and Blackflame`,
    `"Fresh, imaginative fantasy. The Crimson Queen introduces a world filled with powerful magic, clashing cultures, and epic conflicts. I couldn't put it down, and I can't wait to see where Alec Hutson takes this next." - AC Cobble, bestselling author of Benjamin Ashwood `,
    `"Imagine the best of Robert Jordan's worldbuilding skills, laced with Terry Brooks' fluid characterization and topped off with a pinch of David Gemmell's heroic fantasy escapades. Then you get an indication of the fun that awaits when you crack open The Crimson Queen." - Fantasy Book Critic`,
    `"Fan of George RR Martin and Will Wight will love The Crimson Queen by Alec Hutson. Hutson's prose is lyrical and spellbinding and will whisk you away to a world so vivid you can almost imagine you are living the adventure as it unfolds. A mesmerizing and grandiose tale that readers of epic fantasy will not want to miss!" - David Estes, #1 Amazon bestselling author of Fatemarked.`,
    `"I enjoyed the hell out of this book, and I think it deserves to be noticed as a worthy title to put on high fantasy bookshelves." - Bookworm Blues `,
    `"Scads of history and magical lore to delve into . . . a sparkling debut." - Kirkus`,
    `"An incredibly captivating debut with an intricate world-building and a meticulously beautiful writing style . . . The Crimson Queen feels like classic epic fantasy with a modern epic fantasy voice." - Petrik Leo at Novel Notions`
];

export const silver = [
    `The epic story begun in The Crimson Queen continues . . .`,
    `Following the deadly assault on the Scholia by the kith’ketan, Keilan and Nel pursue the paladin Senacus south, hoping to catch him before he can vanish into the Gilded Cities. Nel desires vengeance for the death of her lover, while Keilan hopes to find answers about the immortal sorcerers who sought to challenge the Crimson Queen . . .`,
    `In the Empire of Swords and Flowers a young woman is called upon to avenge her father’s death and return honor to her family . . .`,
    `And after a millennia-long imprisonment, the monstrous Chosen are again free to work their dark will upon the world of man . . .`,
];

export const shadow = [
    `The conclusion to the story that began with The Crimson Queen and continued with the Silver Sorceress . . .`,
    `Scarred by what transpired on the island of the sorceress Niara, Keilan and his companions journey north, bearing a weapon forged to end the unnatural existence of the Chosen. But the sand is trickling through the hourglass, as the demon-children are laboring to bring about a second Raveling and end the age of man . . .`,
    `In the shattered city of Nes Vaneth, Cho Lin struggles to survive her imprisonment by the vicious Skein, while in Dymoria the Crimson Queen prepares to reveal her school of sorcerers by bringing them into the Frostlands to confront the Chosen and their allies . . .`,
    `Meanwhile, the immortal sorceress Alyanna schemes to restore her lost glory and destroy those who have wronged her . . .`,
];

export const dust = [
    `The Streams bind together the vast reaches of the stellar tributary, plied by brave streamsurfers and their telepathic starbeasts. Some of these adventurers are heroes. Some are rogues. And some just want to return from the void with their bodies and minds unbroken . . .`,
    `Kerin thon Talisien is the heir to a legendary name. When he was a young boy, his grandfather swooped down from the stars and rescued him from the slums of his homeworld. But with the death of the infamous old streamsurfer, Kerin and his crew have fallen on hard times, exiled from the Starfarers Guild and forced to take on risky contracts in the shadowy margins of the stellar tributary. A strange encounter in a glimmer den offers a chance at redemption and glory . . . but the stakes are high, with the fate of the Known potentially hanging in the balance.`,
];

export const cleansing = [
    `A sword of green glass.`,
    `A key that opens passages between the worlds.`,
    `The nameless warrior has nothing else. No allies, no armor, not even the memory of how he came to be trapped within this dying land, pursued across a red waste by the monstrous Shriven.`,
    `But all hope is not lost. There are other survivors amidst the desolation, holding clues as to where he has come from and what he must do: Amara, the Red Sword of her tribe, a beautiful and fiery swordswoman obsessed with destroying the demons that plague her people, and Valyra, a young sorceress who can weave the broken threads of this reality into something whole once more.`,
    `Together they will try to resist the evil that stalks the wastes. But this world is only one among many, and in order to unravel his mysteries the warrior must find the doorways which will allow him to stride between the stars and pass into even stranger lands . . .`,
];

export const twilight = [
    `Pursued by the cultists of the Cleansing Flame, Talin and his beautiful companions must flee the City of Masks and find refuge in the Grand and Enlightened Empire of Zim. If the rumors are to be believed, then the sorceress with whom he had fled a dying world might also be waiting for him somewhere among the endless grasslands and obsidian-sheathed peaks of the Twilight Empire. But there is great danger lurking in Zim – a half-mad demigod and the demonic Shriven also seek Talin and the key he carries that can open pathways between the worlds . . .`,
];


export const hollow = [
    `In a ruined temple on a dying world, a man without a past promised the Red Sword of her tribe that he would protect her daughter.`,
    `And then he had lost her. Flung into a very different world, Talin had been forced to navigate this strange new realm as he searched for the girl Valyra. His journey had taken him from Ysala to the Twilight Empire, from the golden grasslands where monsters prowled to the haunted undercity of Zim.`,
    `He had held true to his word, finally rescuing her from the Shriven who wished to use her sorcery to heal the paths between the worlds.`,
    `But after a betrayal by one of his companions, she is now in the clutches of the mysterious Contessa, and he must once again return to the City of Masks.`,
    `Only now the fate of the world hangs in the balance.`,
    `The epic conclusion to the Swords and Saints trilogy.`,
];

export const soiree = [
    `Fourteen tales of speculative fiction from the author of The Crimson Queen.`,
    `Beneath the demon-haunted ruins of Nes Vaneth a priest of the old ways confronts his darkest fears…`,
    `A young hacker risks everything to rescue the mind of her friend from the clutches of a malicious AI…`,
    `On a moon of Mars the guardian of humanity awakens to find the solar system in peril…`,
    `And a dilettante invites a manticore to accompany him to an infamous engagement.`,
    `The Manticore’s Soiree contains three stories set in the world of The Crimson Queen: the novelette Chalice and Chance, the short story The Fall of the Raven Thane, and the first chapter of The Shadow King, the sequel to The Crimson Queen.`,
    `Includes the novelette Twilight’s End, also available as a stand-alone story.`,
];

export const sharded1 = [
    `A thousand years ago the Heart of the World was shattered, its fragments scattered across the lands. In the chaos that followed, martial orders arose to gather the shards, for it was found that great powers were granted when these pieces were bonded to the flesh of the chosen. They are the Sharded Few, warriors imbued with the divine energies that once coursed through the Heart, driven to absorb enough fragments to claim immortality. A progression fantasy by the author of The Raveling.`,
];

export const zog = [
    `What were the Great Old Ones like before they were old?`,
    `In the shadow of cosmic horrors, newly-birthed entity Zogrusz must come to understand his true nature, learn how to wield his dreadful powers, and search for meaning in the mad spaces between the stars. Hopefully, he’ll make some friends along the way.`,
    `Also, there’s a cat. `,
    `A Lovecraftian cozy fantasy.`,
];

export const blade = [
    `On scaled wings they crossed the trackless sea, conquerors from distant lands. None could stand before them – not the queen, surrounded by her bright legions, or the sorcerers steeped in the power of the jade moon, or even the paladins with their swords fashioned from the tears of the Silver Mother.`,
    `Only one who had already lost everything would dare to challenge these invaders. And so a shepherd girl, her will forged in the same fire that consumed her heart, became the last hope of a shattered realm. `,
    `Only she can defy the Empire of Flame and Fang.`,
];