import loadable from '@loadable/component';

//子路由
// import RNovels from './novels'

// 性能优化-代码分割
const Home = loadable(() => import('../components/home/index'));
const About = loadable(() => import('../components/about/index'));
const Novels = loadable(() => import('../components/novels/index'));
// const Blog = loadable(() => import('../components/blog/index'));
const Release = loadable(() => import('../components/release/index'));
const Contact = loadable(() => import('../components/contact/index'));
const Welcome = loadable(() => import('../components/welcome/index'));
const BookDetail = loadable(() => import('../components/novels/bookDetail'));

const NotFound = loadable(() => import('../components/404'));

const routers = [
    {
        path: '/404',
        name: '404',
        component: NotFound,
        exact: true
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        exact: true
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        exact: true
    },
    {
        path: '/novels',
        name: 'Novels',
        component: Novels,
        exact: true
    },
    {
        path: '/novels/bookDetail',
        name: 'Book Detail',
        component: BookDetail,
        exact: true
    },
    // {
    //     path: '/blog',
    //     component: Blog,
    //     exact: true
    // },
    {
        path: '/release',
        name: ' New Release',
        component: Release,
        exact: true
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        exact: true
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome,
        exact: true
    },
];

export default routers;