import { useState, useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { createHashHistory } from 'history';

// createBrowserHistory BrowserRouter 浏览器历史配套使用，需后端配置
// createHashHistory HashRouter hash路由，前端单独使用即可

import './App.css';
import './styles/common.scss';
import 'antd/dist/antd.css';

import routers from './router/index';

import { menuList } from './constant/menu';

// 图片
// import logo from './logo.svg';
import downIcon from './asset/imgs/down.png';
import downActiveIcon from './asset/imgs/downactive.png';


function MenuModal(props) {
  const { visible, hoverIndex, mouseLeave, history } = props;
  const subMenus = hoverIndex > -1 ? menuList[hoverIndex].child : [];
  if (visible && subMenus.length > 0) {
    const bookClick = (item) => {
      // 此中方法接收不到state，不能使用
      // history.push({
      //   pathname: '/novels/bookDetail',
      //   state: { menuIndex: menuIndex, bookIndex: index }
      // });
      history.push(`/novels/bookDetail?id=${item.id}`);
      mouseLeave();
    };
    return (
      <div
        className="App-menu-modal"
      >
        <div className="App-menu-modal-inner" onMouseLeave={mouseLeave}>
          {
            subMenus.map((item, index) => {
              return (
                <div className="App-menu-modal-item" key={index} onClick={bookClick.bind(this, item)}>
                  <img className="App-menu-modal-item-img" src={item.imgUrl} alt={item.name} />
                  <p className="App-menu-modal-item-name">{item.name}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
  return null;
}

function renderDown(list, index, menuIndex) {
  if (list && list.length > 0) {
    return (
      <img src={index === menuIndex ? downActiveIcon : downIcon} className="App-header-down" alt="icon" />
    )
  }
  return null;
}

function App() {
  const history = createHashHistory();
  // console.log('----CL---history---App----', history);
  const [visible, setOn] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const mouseOn = (index) => {
    setHoverIndex(index);
    setOn(true);
  };
  const menuMouseLeave = (index) => {
    if (menuList[index].child.length === 0) {
      setHoverIndex(-1);
    }
  }
  const mouseLeave = () => {
    setHoverIndex(-1);
    setOn(false);
  }
  const mouseClick = (item, index) => {
    setMenuIndex(index);
    // jump page
    history.push(item.path);
  };

  // 某状态变化后再执行
  useEffect(()=>{
    console.log("一次执行后，pathname值发生变化后会再次执行");
    const menuPath = history.location.pathname.split('/')[1];
    const idx = menuList.findIndex(item => item.path === `/${menuPath}`);
    setMenuIndex(idx);
  },[history.location.pathname]);

  const isSmallScreen = window.innerWidth < 950;
  const initTitle = 'Alec Hutson - Official Author Website - Fantasy Novels';

  return (
    <div className="App">
      <HashRouter>
        <header className="App-header">
          {
            menuList.map((item, index) => {
              return (
                <div className={`App-header-item ${index === menuIndex ? 'active' : ''}`}
                    key={index}
                    onMouseEnter={isSmallScreen ? null : mouseOn.bind(this, index)}
                    onMouseLeave={menuMouseLeave.bind(this, index)}
                    onClick={mouseClick.bind(this, item, index)}
                >
                  { item.name }
                  { renderDown(item.child, index, menuIndex) }
                </div>
              )
            })
          }
        </header>
        <Switch>
          <Redirect exact from='/' to='/home' />
          {
            routers.map((router, index) => {
              return (
                <Route
                  exact={router.exact}
                  key={index}
                  path={router.path}
                  render={(props) => {

                    document.title = router.name === 'Home' ? initTitle : `${router.name} - ${initTitle}`;
                    // console.log('---CL---router page--props---', props);
                    return (
                      <router.component {...props}>
                        <Switch>
                          {
                            router.children?.map((item, idx) => {
                              return (
                                <Route
                                  exact={item.exact}
                                  key={idx}
                                  path={item.path}
                                  component={item.component}
                                >
                                </Route>
                              )
                            })
                          }
                          <Redirect to='/404' />
                        </Switch>
                      </router.component>
                    )
                  }}
                ></Route>
              )
            })
          }
          <Redirect to='/404' />
        </Switch>
        <MenuModal visible={visible} hoverIndex={hoverIndex} mouseLeave={mouseLeave} history={history}/>
      </HashRouter>
    </div>
  );
}

export default App;
